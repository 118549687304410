import {
  faAbacus,
  faBallotCheck,
  faBarcodeRead,
  faBox,
  faBoxes,
  faCartFlatbedBoxes,
  faCashRegister,
  faChartLineUp,
  faChartPie,
  faClipboardList,
  faClipboardListCheck,
  faFileInvoiceDollar,
  faHandHoldingDollar,
  faInboxOut,
  faIndustryWindows,
  faInventory,
  faMoneyCheckAlt,
  faPalletAlt,
  faPaperPlaneTop,
  faPenRuler,
  faPersonCarryBox,
  faScanner,
  faSearchLocation,
  faTicket,
  faWarehouseAlt,
  faWarning,
} from '@fortawesome/pro-regular-svg-icons';
import { faUsers } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AccountBalance,
  AddCircleOutline,
  AlternateEmail,
  Apps,
  CallSplit,
  CardGiftcard,
  CastConnected,
  Category,
  ContactPhone,
  Description,
  ExpandLess,
  ExpandMore,
  Feedback,
  Filter9Plus,
  InsertLink,
  LiveHelp,
  LocalShipping,
  Payment,
  PermMedia,
  Poll,
  Public,
  RateReview,
  School,
  SettingsOutlined,
  ShoppingBasket,
  VerifiedUser,
  Widgets,
} from '@mui/icons-material';
import { Collapse, Drawer, List, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';
import React, { Component } from 'react';

import dispatchers from './dispatchers';
import connect from '../../../lib/connect';
import CustomBadge from '../../partial/CustomBadge';
import HelpAndFeedback from '../../partial/HelpAndFeedback';

interface Props extends GlobalProps {
  openLeftDrawerItem: any;
}

interface State {
  helpAndFeedbackModal: boolean;
}

// @ts-ignore
if (process.browser && window) {
  // @ts-ignore
  window.Tawk_API = window.Tawk_API || {};
}

// @ts-ignore
const tawkClient = process.browser && window?.Tawk_API;

const prefetchRoutes = [
  '/customers',
  '/products',
  '/shipping',
  '/orders',
  '/finance/credits',
  '/finance/invoices',
  '/finance/payments',
  '/finance/transactions-log',
  '/finance/reports',
  '/marketing/reviews',
  '/marketing/coupons',
  '/customer-support/calls',
  '/customer-support/tickets',
  '/customer-support/tickets/add',
  '/customer-support/settings',
  '/site/cdn',
  '/site/themes',
  '/site/email-templates',
  '/site/error-pages',
  '/site/settings',
  '/permissions',
];
const isDev = process.env.ENV !== 'production';
const leftDrawerWidth = 225;
const styles = (theme: any): any => ({
  root: {
    // width: '100%',
    padding: 0,
    paddingTop: 58,
    maxWidth: 360,
    background: theme.palette.background.paper,
  },
  nested: { paddingLeft: theme.spacing(2) },
  appBarShift: {
    marginLeft: leftDrawerWidth,
    width: `calc(100% - ${leftDrawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  navIconHide: { [theme.breakpoints.up('md')]: { display: 'none' } },
  hide: { display: 'none' },
  drawerInner: { width: `calc(${leftDrawerWidth}px - 1px)` },
  drawerPaper: {
    position: 'relative',
    height: 'auto',
    minHeight: '100vh',
    width: leftDrawerWidth,
  },
  drawerRoot: {
    // position: 'relative',
    height: '100vh', // 'calc(100vh + 50px)',
    maxHeight: '100vh', // 'calc(100vh + 50px)',
    minHeight: '100vh',
    overflowX: 'scroll',
  },
  listItemIcon: {
    margin: 0,
    padding: 0,
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: 24,
    minWidth: 24,
  },
});

const displayCount = (count: string): string | boolean => false; // count; // count ? '*' : false;

class LeftDrawerItems extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = { helpAndFeedbackModal: false };
  }

  componentDidMount() {
    // const { router } = this.props;
    // prefetchRoutes.forEach(route => router.prefetch(route));
  }

  toggleItem = (name = '') => {
    const {
      redux: { state },
      openLeftDrawerItem,
    } = this.props;

    if (state.leftDrawOpenItem === name) {
      return () => openLeftDrawerItem();
    }
    return () => openLeftDrawerItem(name);
  };

  toggleHelpAndFeedbackModal = () => {
    const { helpAndFeedbackModal } = this.state;
    this.setState({ helpAndFeedbackModal: !helpAndFeedbackModal });
  };

  render() {
    const { helpAndFeedbackModal } = this.state;
    const {
      classes,
      user,
      router,
      redux: { state },
    } = this.props;
    const { pathname } = router;
    const { hideDisabledMenus } = state.user;

    return (
      <Drawer
        variant="persistent"
        classes={{
          root: classes.drawerRoot,
          paper: classes.drawerPaper,
        }}
        open={state.leftDrawer}
      >
        <div className={classes.drawerInner}>
          <HelpAndFeedback open={helpAndFeedbackModal} onClose={this.toggleHelpAndFeedbackModal} />
          <List className={classes.root}>
            <ListItem
              button
              disabled={!user.hasRule(['dashboard-preview'])}
              onClick={() => router.push('/')}
              selected={pathname === '/'}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <Public />
              </ListItemIcon>
              <ListItemText inset primary="Dashboard" />
            </ListItem>
            {(!hideDisabledMenus || (hideDisabledMenus && user.can('read', 'v1/objects/reports'))) && (
              <ListItem
                button
                disabled={!user.can('read', 'v1/objects/reports')}
                onClick={() => router.push('/reports')}
                selected={pathname === '/reports'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <Poll />
                </ListItemIcon>
                <ListItemText inset primary="Reports" />
              </ListItem>
            )}
            <ListSubheader>CUSTOMER SUPPORT</ListSubheader>
            {/*{(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['customer-support'])))*/}
            {/*&& <ListItem*/}
            {/*  button*/}
            {/*  disabled={true || !user.hasRule(['customer-support'])}*/}
            {/*  onClick={() => router.push('/customer-support/tickets/add')}*/}
            {/*  selected={pathname === '/customer-support/tickets/add'}*/}
            {/*>*/}
            {/*  <ListItemIcon className={classes.listItemIcon}><AddCircleOutline/></ListItemIcon>*/}
            {/*  <ListItemText inset primary="New Ticket"/>*/}
            {/*</ListItem>}*/}
            {/*{(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['customer-support'])))*/}
            {/*&& <ListItem*/}
            {/*  button*/}
            {/*  disabled={true || !user.hasRule(['customer-support'])}*/}
            {/*  onClick={() => router.push({*/}
            {/*    pathname: '/customer-support/tickets',*/}
            {/*    query: { tab: 'open' },*/}
            {/*  })}*/}
            {/*  selected={pathname === '/customer-support/tickets'}*/}
            {/*>*/}
            {/*  <ListItemIcon className={classes.listItemIcon}>*/}
            {/*    <CustomBadge*/}
            {/*      color={state.badges.totalTickets ? 'secondary' : 'default'}*/}
            {/*      badgeContent={displayCount(state.badges.totalTickets)}*/}
            {/*    >*/}
            {/*      <QuestionAnswer/>*/}
            {/*    </CustomBadge>*/}
            {/*  </ListItemIcon>*/}
            {/*  <ListItemText inset primary="Tickets"/>*/}
            {/*</ListItem>}*/}
            {/*{(!hideDisabledMenus || (hideDisabledMenus && user.can('read', 'customer support')))*/}
            {/*&& <ListItem*/}
            {/*  button*/}
            {/*  disabled={true || !user.can('read', 'calls') && !user.can('read', 'customer support')}*/}
            {/*  onClick={() => router.push('/customer-support/calls')}*/}
            {/*>*/}
            {/*  <ListItemIcon className={classes.listItemIcon}><Call/></ListItemIcon>*/}
            {/*  <ListItemText inset primary="Calls"/>*/}
            {/*</ListItem>}*/}
            {(!hideDisabledMenus ||
              (hideDisabledMenus &&
                user.hasRule([
                  'customer-support',
                  'sales-representative',
                  'sales-broker',
                  'marketing',
                  'shipper-manager',
                ]))) && (
              <ListItem
                button
                disabled={
                  !user.hasRule([
                    'customer-support',
                    'sales-representative',
                    'sales-broker',
                    'marketing',
                    'shipper-manager',
                  ])
                }
                onClick={() => router.push('/customer-support/new-order')}
                selected={pathname === '/customer-support/new-order'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <AddCircleOutline />
                </ListItemIcon>
                <ListItemText inset primary="New Order" />
              </ListItem>
            )}
            {(!hideDisabledMenus ||
              (hideDisabledMenus &&
                user.hasRule(['customer-support', 'sales-representative', 'finance', 'sales-broker']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['customer-support', 'sales-representative', 'finance', 'sales-broker'])}
                onClick={() => router.push('/customer-support/customers')}
                selected={pathname === '/customer-support/customers'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={faUsers} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText inset primary="Customers" />
              </ListItem>
            )}
            {(!hideDisabledMenus ||
              (hideDisabledMenus && user.hasRule(['customer-support', 'sales-representative', 'finance']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['customer-support', 'sales-representative', 'finance'])}
                onClick={() => router.push('/customer-support/orders')}
                selected={pathname === '/customer-support/orders'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <ShoppingBasket />
                </ListItemIcon>
                <ListItemText inset primary="Sales Orders" />
              </ListItem>
            )}
            {(!hideDisabledMenus ||
              (hideDisabledMenus && user.hasRule(['customer-support', 'sales-representative', 'finance']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['customer-support', 'sales-representative', 'finance'])}
                onClick={() => router.push('/customer-support/escalated-orders')}
                selected={pathname === '/customer-support/escalated-orders'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={faWarning} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText inset primary="Escalated Orders" />
              </ListItem>
            )}
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['sales-representative']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['sales-representative'])}
                onClick={() => router.push('/customer-support/incoming-pos')}
                selected={pathname === '/customer-support/incoming-pos'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <CustomBadge
                    color={state.badges.totalPendingPOs ? 'secondary' : 'default'}
                    badgeContent={displayCount(state.badges.totalPendingPOs)}
                  >
                    <Description />
                  </CustomBadge>
                </ListItemIcon>
                <ListItemText inset primary="Incoming POs" />
              </ListItem>
            )}
            {(!hideDisabledMenus ||
              (hideDisabledMenus && user.hasRule(['customer-support', 'sales-representative', 'shipper']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['customer-support', 'sales-representative', 'shipper', 'finance'])}
                onClick={() => router.push('/customer-support/fulfillment-orders')}
                selected={pathname === '/customer-support/fulfillment-orders'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={faCartFlatbedBoxes} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText inset primary="Fulfillment Orders" />
              </ListItem>
            )}
            <ListSubheader>FINANCE</ListSubheader>
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['finance']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['finance'])}
                onClick={() => router.push('/finance/credits')}
                selected={pathname === '/finance/credits'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <Payment />
                </ListItemIcon>
                <ListItemText inset primary="Credits" />
              </ListItem>
            )}
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['finance']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['finance'])}
                onClick={() => router.push('/finance/invoices')}
                selected={pathname === '/finance/invoices'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <CustomBadge
                    color={state.badges.totalUnpaidInvoices ? 'secondary' : 'default'}
                    badgeContent={displayCount(state.badges.totalUnpaidInvoices)}
                  >
                    <FontAwesomeIcon icon={faFileInvoiceDollar} style={{ width: 24, height: 24 }} />
                  </CustomBadge>
                </ListItemIcon>
                <ListItemText inset primary="Invoices" />
              </ListItem>
            )}
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['finance']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['finance'])}
                onClick={() => router.push('/finance/refunds')}
                selected={pathname === '/finance/refunds'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={faFileInvoiceDollar} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText inset primary="Refunds" />
              </ListItem>
            )}
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['finance']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['finance'])}
                onClick={() => router.push('/finance/payments')}
                selected={pathname === '/finance/payments'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={faMoneyCheckAlt} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText inset primary="Payments" />
              </ListItem>
            )}
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['finance']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['finance'])}
                onClick={() => router.push('/finance/transactions')}
                selected={pathname === '/finance/transactions'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={faCashRegister} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText inset primary="Transactions" />
              </ListItem>
            )}
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['finance']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['finance'])}
                onClick={() => router.push('/finance/accounting')}
                selected={pathname === '/finance/accounting'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <AccountBalance />
                </ListItemIcon>
                <ListItemText inset primary="Accounting" />
              </ListItem>
            )}
            <ListSubheader>FULFILLMENT</ListSubheader>
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['shipper']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['shipper'])}
                onClick={() => router.push('/fulfillment/pick-lists')}
                selected={pathname === '/fulfillment/pick-lists'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={faClipboardListCheck} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText inset primary="Pick Lists" />
              </ListItem>
            )}
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['shipper']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['shipper'])}
                onClick={() => router.push('/fulfillment/shipping')}
                selected={pathname === '/fulfillment/shipping'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <CustomBadge
                    color={state.badges.totalUnshippedOrders ? 'secondary' : 'default'}
                    badgeContent={displayCount(state.badges.totalUnshippedOrders)}
                  >
                    <LocalShipping />
                  </CustomBadge>
                </ListItemIcon>
                <ListItemText inset primary="Shipping Queue" />
              </ListItem>
            )}
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['shipper']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['shipper'])}
                onClick={() => router.push('/fulfillment/batch-shipping')}
                selected={pathname === '/fulfillment/batch-shipping'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <Filter9Plus />
                </ListItemIcon>
                <ListItemText inset primary="Batch Shipping" />
              </ListItem>
            )}
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['shipper', 'shipper-manager']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['shipper', 'shipper-manager'])}
                onClick={() => router.push('/fulfillment/new-shipment')}
                selected={pathname === '/fulfillment/new-shipment'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <AddCircleOutline />
                </ListItemIcon>
                <ListItemText inset primary="New Shipment" />
              </ListItem>
            )}
            {(!hideDisabledMenus ||
              (hideDisabledMenus && user.hasRule(['customer-support', 'sales-representative', 'shipper']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['customer-support', 'sales-representative', 'shipper', 'finance'])}
                onClick={() => router.push('/fulfillment/shipping-log')}
                selected={pathname === '/fulfillment/shipping-log'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={faPalletAlt} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText inset primary="Shipping Log" />
              </ListItem>
            )}
            {/*{(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['customer-support', 'shipper'])))*/}
            {/*  && <ListItem*/}
            {/*    button*/}
            {/*    disabled={!user.hasRule(['customer-support', 'shipper', 'finance'])}*/}
            {/*    onClick={() => router.push('/fulfillment/postage-log')}*/}
            {/*    selected={pathname === '/fulfillment/postage-log'}*/}
            {/*  >*/}
            {/*    <ListItemIcon className={classes.listItemIcon}>*/}
            {/*      <FontAwesomeIcon*/}
            {/*        icon={faMailBulk}*/}
            {/*        style={{ width: 24, height: 24 }}*/}
            {/*      />*/}
            {/*    </ListItemIcon>*/}
            {/*    <ListItemText inset primary="Postage Log"/>*/}
            {/*  </ListItem>}*/}
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['shipper-manager']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['shipper-manager'])}
                onClick={() => router.push('/fulfillment/scan-forms')}
                selected={pathname === '/fulfillment/scan-forms'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={faBarcodeRead} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText inset primary="Scan Forms" />
              </ListItem>
            )}
            <ListSubheader>LOGISTICS</ListSubheader>
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['inventory-manager']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['inventory-manager'])}
                onClick={() => router.push('/logistics/inventory')}
                selected={pathname === '/logistics/inventory'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={faScanner} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText inset primary="Inventory Tracker" />
              </ListItem>
            )}
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['inventory-manager', 'inventory-viewer']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['inventory-manager', 'inventory-viewer'])}
                onClick={() => router.push('/logistics/inventory-log')}
                selected={pathname === '/logistics/inventory-log'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={faClipboardList} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText inset primary="Inventory Log" />
              </ListItem>
            )}
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['inventory-manager', 'inventory-viewer']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['inventory-manager', 'inventory-viewer'])}
                onClick={() => router.push('/logistics/counters')}
                selected={pathname === '/logistics/counters'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={faAbacus} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText inset primary="Counters" />
              </ListItem>
            )}
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['inventory-manager', 'inventory-viewer']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['inventory-manager', 'inventory-viewer'])}
                onClick={() => router.push('/logistics/lookup')}
                selected={pathname === '/logistics/lookup'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={faSearchLocation} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText inset primary="Lookup" />
              </ListItem>
            )}
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['inventory-manager', 'supply-manager']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['inventory-manager', 'supply-manager'])}
                onClick={() => router.push('/logistics/transfers')}
                selected={pathname === '/logistics/transfers'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <CustomBadge
                    color={state.badges.totalShippedTransfers ? 'secondary' : 'default'}
                    badgeContent={displayCount(state.badges.totalShippedTransfers)}
                  >
                    <CallSplit />
                  </CustomBadge>
                </ListItemIcon>
                <ListItemText inset primary="Transfers" />
              </ListItem>
            )}
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['inventory-manager']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['inventory-manager'])}
                onClick={() => router.push('/logistics/locations')}
                selected={pathname === '/logistics/locations'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={faInventory} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText inset primary="Locations" />
              </ListItem>
            )}
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['inventory-manager']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['inventory-manager'])}
                onClick={() => router.push('/logistics/warehouses')}
                selected={pathname === '/logistics/warehouses'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={faWarehouseAlt} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText inset primary="Warehouses" />
              </ListItem>
            )}
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['inventory-manager']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['inventory-manager'])}
                onClick={() => router.push('/logistics/fulfillment-zones')}
                selected={pathname === '/logistics/fulfillment-zones'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={faPersonCarryBox} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText inset primary="Fulfillment Zones" />
              </ListItem>
            )}
            <ListSubheader>SUPPLY CHAIN</ListSubheader>
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['sales-representative']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['supply-manager', 'finance'])}
                onClick={() => router.push('/supply-chain/forecasting')}
                selected={pathname === '/supply-chain/forecasting'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={faChartLineUp} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText inset primary="Sales Forecasting" />
              </ListItem>
            )}
            {isDev && (!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['sales-representative']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['supply-manager', 'finance'])}
                onClick={() => router.push('/supply-chain/demand-planning')}
                selected={pathname === '/supply-chain/demand-planning'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={faChartPie} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText inset primary="Demand Planning" />
              </ListItem>
            )}
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['supply-manager']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['supply-manager'])}
                onClick={() => router.push('/supply-chain/outgoing-pos')}
                selected={pathname === '/supply-chain/outgoing-pos'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  {/*<CustomBadge*/}
                  {/*  color={state.badges.totalPendingPOs ? 'secondary' : 'default'}*/}
                  {/*  badgeContent={displayCount(state.badges.totalPendingPOs)}*/}
                  {/*>*/}
                  <Description />
                  {/*</CustomBadge>*/}
                </ListItemIcon>
                <ListItemText inset primary="Outgoing POs" />
              </ListItem>
            )}
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['supply-manager']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['supply-manager'])}
                onClick={() => router.push('/supply-chain/vendors')}
                selected={pathname === '/supply-chain/vendors'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <ContactPhone />
                </ListItemIcon>
                <ListItemText inset primary="Vendors" />
              </ListItem>
            )}
            {(!hideDisabledMenus ||
              (hideDisabledMenus && user.hasRule(['sales-representative', 'supply-manager']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['supply-manager', 'product-manager'])}
                onClick={() => router.push('/supply-chain/materials')}
                selected={pathname === '/supply-chain/materials'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <Category />
                </ListItemIcon>
                <ListItemText inset primary="Materials" />
              </ListItem>
            )}
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['supply-manager', 'product-manager']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['supply-manager', 'product-manager'])}
                onClick={() => router.push('/supply-chain/products')}
                selected={pathname === '/supply-chain/products'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={faBoxes} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText inset primary="Products" />
              </ListItem>
            )}
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['supply-manager', 'product-manager']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['supply-manager', 'product-manager'])}
                onClick={() => router.push('/supply-chain/price-lists')}
                selected={pathname === '/supply-chain/price-lists'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={faHandHoldingDollar} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText inset primary="Price Lists" />
              </ListItem>
            )}
            {(!hideDisabledMenus ||
              (hideDisabledMenus && user.hasRule(['inventory-manager', 'inventory-viewer', 'supply-manager']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['inventory-manager', 'inventory-viewer', 'supply-manager'])}
                onClick={() => router.push('/supply-chain/stock-lots')}
                selected={pathname === '/supply-chain/stock-lots'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={faBox} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText inset primary="Stock Lots" />
              </ListItem>
            )}
            {(!hideDisabledMenus ||
              (hideDisabledMenus && user.hasRule(['inventory-manager', 'inventory-viewer', 'supply-manager']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['inventory-manager', 'inventory-viewer', 'supply-manager'])}
                onClick={() => router.push('/supply-chain/build-orders')}
                selected={pathname === '/supply-chain/build-orders'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={faIndustryWindows} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText inset primary="Build Orders" />
              </ListItem>
            )}
            {(!hideDisabledMenus ||
              (hideDisabledMenus && user.hasRule(['inventory-manager', 'inventory-viewer', 'supply-manager']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['inventory-manager', 'inventory-viewer', 'supply-manager'])}
                onClick={() => router.push('/supply-chain/build-templates')}
                selected={pathname === '/supply-chain/build-templates'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={faPenRuler} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText inset primary="Build Templates" />
              </ListItem>
            )}
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['supply-manager']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['supply-manager'])}
                onClick={() => router.push('/supply-chain/pending-review')}
                selected={pathname === '/supply-chain/pending-review'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <CustomBadge
                    color={state.badges.totalPendingReview ? 'secondary' : 'default'}
                    badgeContent={displayCount(state.badges.totalPendingReview)}
                  >
                    <FontAwesomeIcon icon={faBallotCheck} style={{ width: 24, height: 24 }} />
                  </CustomBadge>
                </ListItemIcon>
                <ListItemText inset primary="Pending Review" />
              </ListItem>
            )}
            <ListSubheader>MARKETING</ListSubheader>
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['developer']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['developer'])}
                onClick={() => router.push('/technology/media')}
                selected={pathname === '/technology/media'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <PermMedia />
                </ListItemIcon>
                <ListItemText inset primary="Media" />
              </ListItem>
            )}
            {(!hideDisabledMenus ||
              (hideDisabledMenus && user.hasRule(['customer-support', 'sales-representative', 'marketing']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['customer-support', 'sales-representative', 'marketing'])}
                onClick={() => router.push('/marketing/reviews')}
                selected={pathname === '/marketing/reviews'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <RateReview />
                </ListItemIcon>
                <ListItemText inset primary="Reviews" />
              </ListItem>
            )}
            {(!hideDisabledMenus ||
              (hideDisabledMenus && user.hasRule(['customer-support', 'sales-representative', 'marketing']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['customer-support', 'sales-representative', 'marketing'])}
                onClick={() => router.push('/marketing/coupons')}
                selected={pathname === '/marketing/coupons'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <CardGiftcard />
                </ListItemIcon>
                <ListItemText inset primary="Coupons" />
              </ListItem>
            )}
            {(!hideDisabledMenus ||
              (hideDisabledMenus && user.hasRule(['customer-support', 'sales-representative', 'marketing']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['customer-support', 'sales-representative', 'marketing'])}
                onClick={() => router.push('/marketing/invite-codes')}
                selected={pathname === '/marketing/invite-codes'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={faTicket} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText inset primary="Invite Codes" />
              </ListItem>
            )}
            <ListSubheader>OTHER</ListSubheader>
            {/*{isDev && (!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['super-admin'])))*/}
            {/*  && <ListItem*/}
            {/*    button*/}
            {/*    disabled={!user.hasRule(['super-admin'])}*/}
            {/*    onClick={() => router.push('/rule-engine')}*/}
            {/*    selected={pathname === '/rule-engine'}*/}
            {/*  >*/}
            {/*    <ListItemIcon className={classes.listItemIcon}><Widgets/></ListItemIcon>*/}
            {/*    <ListItemText inset primary="Rule Engine"/>*/}
            {/*  </ListItem>}*/}
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['developer']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['developer'])}
                onClick={() => router.push('/technology/email-templates')}
                selected={pathname === '/technology/email-templates'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <AlternateEmail />
                </ListItemIcon>
                <ListItemText inset primary="Email Templates" />
              </ListItem>
            )}
            {(!hideDisabledMenus ||
              (hideDisabledMenus && user.hasRule(['developer', 'finance', 'emails-log-viewer']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['developer', 'finance', 'emails-log-viewer'])}
                onClick={() => router.push('/technology/email-senders')}
                selected={pathname === '/technology/email-senders'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={faPaperPlaneTop} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText inset primary="Email Senders" />
              </ListItem>
            )}
            {(!hideDisabledMenus ||
              (hideDisabledMenus && user.hasRule(['developer', 'finance', 'emails-log-viewer']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['developer', 'finance', 'emails-log-viewer'])}
                onClick={() => router.push('/technology/emails-log')}
                selected={pathname === '/technology/emails-log'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FontAwesomeIcon icon={faInboxOut} style={{ width: 24, height: 24 }} />
                </ListItemIcon>
                <ListItemText inset primary="Emails Log" />
              </ListItem>
            )}
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['super-admin']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['super-admin'])}
                onClick={() => router.push('/rules-engine')}
                selected={pathname === '/rules-engine'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <Widgets />
                </ListItemIcon>
                <ListItemText inset primary="Rules Engine" />
              </ListItem>
            )}
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['super-admin']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['super-admin'])}
                onClick={() => router.push('/channels')}
                selected={pathname === '/channels'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <CastConnected />
                </ListItemIcon>
                <ListItemText inset primary="Channels" />
              </ListItem>
            )}
            {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['super-admin']))) && (
              <ListItem
                button
                disabled={!user.hasRule(['super-admin'])}
                onClick={() => router.push('/adapters')}
                selected={pathname === '/adapters'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <Apps />
                </ListItemIcon>
                <ListItemText inset primary="Adapters" />
              </ListItem>
            )}
            {(!hideDisabledMenus || (hideDisabledMenus && user.can('manage', 'v1/objects/users'))) && (
              <ListItem
                button
                disabled={!user.can('manage', 'v1/objects/users')}
                onClick={() => router.push('/users')}
                selected={pathname === '/users'}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <VerifiedUser />
                </ListItemIcon>
                <ListItemText inset primary="Users" />
              </ListItem>
            )}
            <ListItem button onClick={this.toggleItem('ProjectSettings')}>
              <ListItemIcon className={classes.listItemIcon}>
                <SettingsOutlined />
              </ListItemIcon>
              <ListItemText inset primary="Settings" />
              {state.leftDrawOpenItem === 'ProjectSettings' ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse component="li" in={state.leftDrawOpenItem === 'ProjectSettings'} timeout="auto" unmountOnExit>
              <List disablePadding>
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() => router.push('/settings/profile')}
                  selected={pathname === '/settings/profile'}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <InsertLink />
                  </ListItemIcon>
                  <ListItemText inset primary="Profile" />
                </ListItem>
                {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['super-admin']))) && (
                  <ListItem
                    button
                    className={classes.nested}
                    disabled={!user.hasRule(['super-admin'])}
                    onClick={() => router.push('/settings/account')}
                    selected={pathname === '/settings/account'}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <InsertLink />
                    </ListItemIcon>
                    <ListItemText inset primary="Account" />
                  </ListItem>
                )}
                {(!hideDisabledMenus || (hideDisabledMenus && user.hasRule(['shipper-manager', 'super-admin']))) && (
                  <ListItem
                    button
                    className={classes.nested}
                    disabled={!user.hasRule(['shipper-manager'])}
                    onClick={() => router.push('/settings/shipping')}
                    selected={pathname === '/settings/shipping'}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <InsertLink />
                    </ListItemIcon>
                    <ListItemText inset primary="Shipping" />
                  </ListItem>
                )}
              </List>
            </Collapse>
            <br />
            <ListItem button onClick={() => window.open('https://kentro.io/feature-requests/', '_blank')}>
              <ListItemIcon className={classes.listItemIcon}>
                <Feedback />
              </ListItemIcon>
              <ListItemText inset primary="Feature Request" />
            </ListItem>
            <ListItem button onClick={() => window.open('https://kentro.readme.io', '_blank')}>
              <ListItemIcon className={classes.listItemIcon}>
                <School />
              </ListItemIcon>
              <ListItemText inset primary="Documentation" />
            </ListItem>
            {tawkClient?.maximize && (
              <ListItem button onClick={() => tawkClient?.maximize()}>
                <ListItemIcon className={classes.listItemIcon}>
                  <LiveHelp color="primary" />
                </ListItemIcon>
                <ListItemText inset primary="Help" />
              </ListItem>
            )}
          </List>
        </div>
      </Drawer>
    );
  }
}

export default connect({ styles, dispatchers })(LeftDrawerItems);
