import { Cancel } from '@mui/icons-material';
import { Chip, MenuItem, Paper, TextField, Tooltip, Typography } from '@mui/material';
import { emphasize } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import shortid from 'shortid';

import api from '../../../lib/api-client';
import logger from '../../../lib/logger';

interface Props extends GlobalProps {
  value: any;
  onChange: any;
  className: any;
  schema: any;
  placeholder: string;
  query: any;
  service: string;
  options: any[];
  filterOptions: any;
  extraOptions: any[];
  onMenuScrollToBottom: any;
  closeMenuOnSelect: boolean;
  cache: boolean;
  isClearable?: boolean;
  removeSelected: boolean;
  dataResolver: any;
  classes: any;
  api: any;
  logger: any;
  label?: string;
  disabled?: boolean;
  populateDisabled?: boolean;
  multi?: boolean;
  search?: boolean;
  error?: any;
}

interface State {
  limit: number;
  input: string;
  loading: boolean;
  data: any[];
  quickSearchField?: string | string[];
}

const styles = theme => ({
  root: {
    minWidth: 200,
    borderColor: '#f44336 !important',
    minHeight: '20.75px !important',
    marginRight: theme.spacing(1),
    '&:last-child': { marginRight: 0 },
  },
  input: {
    height: 'unset !important',
    display: 'flex',
    minHeight: '26.75px !important',
    padding: '0 !important',
    width: '100% !important',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  indicatorsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '30.75px !important',
    marginRight: theme.spacing(1),
  },
  chip: {
    height: theme.spacing(3),
    marginTop: 2,
    '&:first-child': { marginLeft: 0 },
    '&:last-child': { marginRight: 0 },
  },
  chipDeleteIcon: { margin: 0 },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: { padding: `${parseInt(theme.spacing(1))} ${parseInt(theme.spacing(1))}` },
  singleValue: { fontSize: 16 },
  placeholder: {
    left: 12,
    fontSize: 16,
    position: 'absolute',
  },
  paper: {
    position: 'absolute',
    zIndex: 10000,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
});

function NoOptionsMessage(props: any) {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

const inputComponent = React.forwardRef(({ inputRef, ...props }: any, ref) => {
  return <div ref={inputRef} {...props} />;
});

function Control(props: any) {
  return (
    <TextField
      fullWidth
      error={props.selectProps.error}
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      margin="none"
      variant="outlined"
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props: any) {
  const withTooltip = props.children?.length > 55;

  if (withTooltip) {
    return (
      <Tooltip
        id={shortid.generate()}
        title={props.children}
        style={{
          zIndex: 10000000,
          position: 'relative',
        }}
        placement="right"
      >
        <MenuItem
          selected={props.isFocused}
          component="div"
          style={{
            fontSize: 15,
            fontWeight: props.isSelected ? 500 : 400,
            padding: 2,
            overflow: 'hidden',
          }}
          {...props.innerProps}
        >
          {props.children}
        </MenuItem>
      </Tooltip>
    );
  }

  return (
    <MenuItem
      selected={props.isFocused}
      component="div"
      style={{
        fontSize: 14,
        fontWeight: props.isSelected ? 500 : 400,
        padding: 2, // '2px 8px',
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props: any) {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.placeholder} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function SingleValue(props: any) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props: any) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function IndicatorsContainer(props: any) {
  return <div className={props.selectProps.classes.indicatorsContainer}>{props.children}</div>;
}

function MultiValue(props: any) {
  if (!props.children) return null;
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<Cancel className={props.selectProps.classes.chipDeleteIcon} {...props.removeProps} />}
    />
  );
}

function Menu(props: any) {
  return (
    <Paper className={classNames(props.selectProps.classes.paper, 'custom-select-menu')} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const componentsOverwrite = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  IndicatorsContainer,
};

// TODO migrate from class, "Tooltip" doesn't work with class component
const IntegrationReactSelect = (props: Props) => {
  const {
    classes,
    onChange,
    filterOptions,
    label,
    disabled,
    search = true,
    cache = true,
    theme,
    multi,
    className,
    closeMenuOnSelect = false,
    placeholder = props.search ? 'type to search...' : 'select...',
    isClearable = true,
  } = props;
  const selectStyles = {
    menuPortal: base => ({
      ...base,
      zIndex: '100000 !important',
    }),
    clearIndicator: base => ({
      ...base,
      paddingRight: 0,
      cursor: 'pointer',
    }),
    dropdownIndicator: base => ({
      ...base,
      cursor: 'pointer',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': { font: 'inherit' },
    }),
  };
  const isAsync = Boolean(props.service && !props.options);
  const prevQuery = useRef(props.query);
  const prevOptions = useRef(props.options);

  const [state, setState] = useState<State>({
    limit: 50,
    input: '',
    loading: false,
    data: props.extraOptions?.[0] ? [...props.extraOptions, ...(props.options || [])] : props.options || [],
  });

  const getSelectedValues = () => {
    const { schema } = props;
    const extraOptions = props.extraOptions?.map(v => v?.value) || [];
    const singleValue = !Array.isArray(props.value)
      ? props.value?.value || props.value?.[schema.value] || (props.value?.label ? null : props.value)
      : null;
    const multiValue = Array.isArray(props.value)
      ? props.value
          ?.map(v => v?.value || v?.[schema.value] || (v?.label ? null : v))
          ?.filter(v => !extraOptions.includes(v))
      : null;

    if (!Array.isArray(props.value) && extraOptions.includes(singleValue)) return null;
    if (Array.isArray(props.value) && !multiValue?.[0]) return null;

    return Array.isArray(props.value) ? multiValue : singleValue;
  };

  const populateSelectedOptions = async (res: any, extend = false) => {
    const { schema, service, dataResolver, extraOptions = [] } = props;

    if (dataResolver) res.data = dataResolver(res.data || []);

    const extraProps = schema.extraProps || [];
    // use Set to avoid duplications
    const select = Array.from(new Set([schema.value, schema.label, ...extraProps])).filter(v => v);
    const currentValue = getSelectedValues();
    const valueExist = res?.data?.find(v => v?.[schema.value] === currentValue);
    const withValue = Array.isArray(currentValue) ? Boolean(currentValue?.[0]) : Boolean(currentValue);
    let data = [...extraOptions, ...(extend ? state.data : []), ...mapDataToOptions(res?.data || res || [])];

    // if value is not in the list, add it
    if (currentValue && Array.isArray(res?.data) && withValue && !valueExist) {
      await api.rest
        .service(service)
        .create({
          $mapRequest: {
            method: 'find',
            query: {
              $limit: 1000,
              $select: select,
              ...(props.query || {}),
              [schema.value]: Array.isArray(props.value) ? { $in: currentValue } : currentValue,
            },
          },
        })
        .then(r => {
          if (dataResolver) {
            res.data = [...res.data, ...dataResolver(r?.data || [])];
          } else {
            res.data = [...res.data, ...(r?.data || [])];
          }

          data = [...extraOptions, ...(extend ? state.data : []), ...mapDataToOptions(res?.data || res || [])];
        })
        .catch(() => undefined); // ignore
    }

    setState({ ...state, loading: false, data });
  };

  const getQueryWithInput = (input = '') => {
    const { schema } = props;
    const searchQuery: any = {};
    const extraProps = schema.extraProps || [];

    if (input && Array.isArray(schema.searchBy) && schema.searchBy?.length) {
      searchQuery.$or = [];

      schema.searchBy.forEach(searchBy => {
        const [[key, type]]: any = Object.entries(searchBy);

        if (type === 'number') {
          searchQuery.$or.push({ [key]: Number(input) });
        } else {
          searchQuery.$or.push({
            [key]: {
              $regex: input,
              $options: 'i',
            },
          });
        }
      });
    } else if (input) {
      searchQuery[schema.label] = input
        ? {
            $regex: input,
            $options: 'i',
          }
        : undefined;
    }

    const select = Array.from(
      new Set([schema.value, schema.label, ...extraProps, ...Object.keys(schema.searchBy || {})])
    );

    return { select, query: searchQuery };
  };

  const getOptions = (input = '', skipLoading = false, disabled = props.disabled && !props.populateDisabled) => {
    const { schema, service, options, extraOptions = [] } = props;

    if (disabled) return;

    if (isAsync) {
      const currentValue = getSelectedValues();
      const hasCurrentValue = Array.isArray(props.value) ? currentValue?.[0] : currentValue;
      const res: any = getQueryWithInput(input);

      api.rest
        .service(service)
        .create({
          $mapRequest: {
            method: 'find',
            query: {
              [schema.value]: hasCurrentValue
                ? { $nin: Array.isArray(props.value) ? currentValue : [currentValue] }
                : undefined,
              ...res.query,
              $limit: state.limit,
              $select: res.select,
              ...(props.query || {}),
            },
          },
        })
        .then(res => populateSelectedOptions(res))
        .catch(() => undefined); // ignore
    } else {
      const data = [...extraOptions, ...(options?.filter(o => o.label?.match(new RegExp(input, 'gi'))) || [])];

      setState({
        ...state,
        input,
        data,
        loading: false,
      });
    }
  };

  const onInputChange = (input: string) => {
    if (state.input !== input) {
      return getOptions(input);
    }
  };

  const onMenuScrollToBottom = () => {
    const { schema, service } = props;
    const data = [...(state.data || [])];

    if (isAsync && data?.length >= state.limit) {
      const currentValue = getSelectedValues();
      const res: any = getQueryWithInput(state.input);

      setState({ ...state, loading: true });

      const hasCurrentValue = Array.isArray(props.value) ? currentValue?.[0] : currentValue;

      return api.rest
        .service(service)
        .create({
          $mapRequest: {
            method: 'find',
            query: {
              [schema.value]: hasCurrentValue
                ? { $nin: Array.isArray(props.value) ? currentValue : [currentValue] }
                : undefined,
              ...res.query,
              $select: res.select,
              ...(props.query || {}),
              $skip: data.length,
              $limit: state.limit,
            },
          },
        })
        .then(r => populateSelectedOptions(r, true))
        .catch(logger.error);
    }
  };

  const mapDataToOptions = (data: any[]): any[] => {
    const { schema } = props;

    if (schema) {
      return data.map(item => {
        const extraProps = {};
        const extra = schema.extraProps || [];
        const { labelConstructor } = schema;

        extra.map(p => (extraProps[p] = item[p]));

        if (extra[0]) {
          return {
            extraProps,
            labelConstructor,
            value: item[schema.value],
            label: item[schema.label],
          };
        }

        return {
          labelConstructor,
          value: item[schema.value],
          label: item[schema.label],
        };
      });
    }

    return data;
  };

  const getLabel =
    (schema: any) =>
    (option: any): string => {
      try {
        if (option?.extraProps && typeof schema?.labelConstructor === 'string') {
          const label = new Function(...Object.keys(option.extraProps), `return \`${schema?.labelConstructor}\``);
          return label(...Object.values(option.extraProps));
        }
      } catch (err: any) {
        console.error('getLabel error:', err?.message);
      }

      return option.label;
    };

  const mapExistedValues = (value, state) => {
    if (typeof value !== 'object') {
      return state.data.find(r => r.value === value) || value;
    } else if (Array.isArray(value) && value?.[0] && typeof value?.[0] !== 'object') {
      return value.map(v => state.data.find(r => r.value === v) || v);
    }
    return value;
  };

  const value = mapExistedValues(props.value, state);

  useEffect(() => {
    getOptions();
    return () => setState({ ...state, data: [] });
  }, []);

  useEffect(() => {
    if (isAsync && JSON.stringify(prevQuery?.current) !== JSON.stringify(props.query)) {
      getOptions();
    }
    if (!isAsync && JSON.stringify(prevOptions?.current) !== JSON.stringify(props.options)) {
      setState({
        ...state,
        data: props.extraOptions?.[0] ? [...props.extraOptions, ...(props.options || [])] : props.options || [],
      });
    }
  }, [props.query]);

  return (
    <Select
      {...props}
      className={classNames(classes.root, className)}
      // @ts-ignore
      classes={classes}
      styles={selectStyles}
      textFieldProps={
        label
          ? {
              label,
              InputLabelProps: { shrink: true },
            }
          : {}
      }
      getOptionLabel={getLabel(props.schema)}
      components={componentsOverwrite}
      placeholder={placeholder}
      value={value}
      options={state.data}
      onChange={onChange}
      onInputChange={onInputChange}
      onMenuScrollToBottom={props.onMenuScrollToBottom || onMenuScrollToBottom}
      filterOptions={filterOptions || (opt => opt)}
      cache={cache}
      isMulti={multi}
      isDisabled={disabled}
      isSearchable={search}
      isLoading={state.loading}
      closeMenuOnSelect={closeMenuOnSelect}
      blurInputOnSelect={closeMenuOnSelect}
      isClearable={isClearable}
      menuPosition="fixed"
    />
  );
};

// @ts-ignore
export default withStyles(styles, { withTheme: true })(IntegrationReactSelect) as any;
